export default [
  {
    label: '1 month',
    value: 'short_term',
  },
  {
    label: '6 months',
    value: 'medium_term',
  },
  {
    label: 'all time',
    value: 'long_term',
  },
];
